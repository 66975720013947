﻿function UserInfoBalanceData() {
}

function parseAdditionalBalances(data) {
    try {
        return JSON.parse(CryptoHelper.decrypt(data));
    } catch (e) {
    }
}

UserInfoBalanceData.prototype.updateBalanceData = function (response) {
    if (response.SportsTotalBalance === '' &&
        response.OpenBetsBalanceCustomerCurrency === '' &&
        response.CasinoBalanceCustomerCurrency === '' &&
        response.TotalBalanceCustomerCurrency === '' &&
        !response.AdditionalBalances
    )
    {
        return;
    }

    this.balance = UserInfo.SeamlessCustomerBalance
        ? this.getFormattedBalance(this.sanitizeSeamlessBalance(UserInfo.SeamlessCustomerBalance))
        : response.SportsTotalBalance;
    this.openBetsBalanceCustomerCurrency = response.OpenBetsBalanceCustomerCurrency;
    this.openedBetsBalanceString = this.toUserFormatedCurrencyString(this.openBetsBalanceCustomerCurrency);
    this.sportTotalBalance = this.balance;
    this.SportsbookTotalBalance = parseFloat(this.balance);
    this.sportsbookTotalBalanceString = this.toUserFormatedCurrencyString(this.sportTotalBalance); // sportsbook total balance
    this.CasinoBalance = this.casinoTotalBalance = response.CasinoBalanceCustomerCurrency;
    this.casinoTotalBalanceString = this.toUserFormatedCurrencyString(this.casinoTotalBalance); // casino total balance
    this.TotalBalance = UserInfo.SeamlessCustomerBalance ? UserInfo.SeamlessCustomerBalance : response.TotalBalanceCustomerCurrency;
    var additionalBalances = parseAdditionalBalances(response.AdditionalBalances);
    if (additionalBalances)
    {
        this.CasinoRealBalance = additionalBalances[0];
        this.CasinoBonusBalance = additionalBalances[1];
        this.SportsbookBonusBalance = additionalBalances[2];
        this.LockedRealBalanceCustomerCurrency = additionalBalances[3];
        this.LockedBonusBalanceCustomerCurrency = additionalBalances[4];
        this.CreditBalanceCustomerCurrency = additionalBalances[5];
        this.CreditLimitCustomerCurrency = additionalBalances[6];
        this.UsedCreditsCustomerCurrency = additionalBalances[7];
        this.AvailableFundsCustomerCurrency = additionalBalances[8];
        this.CreditIsEnabled = additionalBalances[9];

        this.NotlockedBalance = this.SportsbookTotalBalance - this.LockedRealBalanceCustomerCurrency - this.LockedBonusBalanceCustomerCurrency;

        if (window.IsNegativeBalanceEnabled) 
        {
            this.WithdrawableSportsbookBalance = this.SportsbookTotalBalance - this.SportsbookBonusBalance;
        }
        else {
            var withdrawableSportsbookBalanceLocal = 0;
            if (this.SportsbookTotalBalance > this.SportsbookBonusBalance) 
            {
                withdrawableSportsbookBalanceLocal = this.SportsbookTotalBalance - this.SportsbookBonusBalance;
            }
            else 
            {
                this.SportsbookBonusBalance = this.SportsbookTotalBalance;
            }
            this.WithdrawableSportsbookBalance = withdrawableSportsbookBalanceLocal;
        }
    }
    this.balanceDataUpdated = true;
    this.setAdditionalBalances();
};

UserInfoBalanceData.prototype.updateOpenBets = function (response) {
    this.OpenBetsCount = response.GetCustomerOpenBetsCountEx;
    this.openFreeBetsBalance = response.OpenFreeBetsBalanceCustomerCurrency;
    this.openFreeBetsBalanceString = this.toUserFormatedCurrencyString(this.openFreeBetsBalance);

    this.openBetsDataUpdated = true;
    this.setAdditionalBalances();
};

UserInfoBalanceData.prototype.setAdditionalBalances = function () {
    if (this.balanceDataUpdated && this.openBetsDataUpdated) {
        UserInfo.balancesInitiated = true;
        var openBetsBalance = this.openBetsBalanceCustomerCurrency + this.openFreeBetsBalance;
        this.openBalanceString = this.toUserFormatedCurrencyString(openBetsBalance);  // open any bets balance

        if (this.balanceConfig !== BalanceConfig.NONE) {
            var totalBalance = this.balance;
            switch (this.balanceConfig) {
                case BalanceConfig.WEEKLYBALANCE:
                    this.WeeklyCashBalance = ((totalBalance + openBetsBalance) - this.creditLimit).toStringEx('#.00');
                    break;
                case BalanceConfig.CASHBALANCE:
                    this.CashBalance = ((totalBalance + openBetsBalance) - this.creditLimit).toStringEx('#.00');
                    break;
                default:
                    break;
            }
        }
    }
};

//Starbet returns the balance separated by comma not by a decimal point
UserInfoBalanceData.prototype.sanitizeSeamlessBalance = function (balance) {
    return  balance.replace(',', '.');
};

UserInfoBalanceData.prototype.getFormattedBalance = function (balance) {
    return '{0:#.00}'.format(parseFloat(balance)); // balance as number
};

window.UserInfoBalanceData = UserInfoBalanceData;
window.parseAdditionalBalances = parseAdditionalBalances;