﻿var APIUser = ((
    function ()
    {
        'use strict';

        var w;
        return function () { return w ? w : (typeof (whl) != 'function' ? w = whl : w = new whl()); };
    })());

/* exported IsAPIDefined */
var IsAPIDefined = function ()
{
    'use strict';

    if (typeof (whl) == 'undefined')
    {
        return false;
    }
    else
    {
        return true;
    }
};

/* exported UseCaptchaLogin */
/* globals useCaptchaLogin */
var UseCaptchaLogin = function ()
{
    'use strict';

    return typeof useCaptchaLogin !== 'undefined' ? useCaptchaLogin : false;
};

/* exported APIDomain */
function APIDomain()
{
    'use strict';

    // this will also return the port
    var uri = window.location.host;
    var uriParts = uri.split('.');
    if (uriParts.length > 2)
    {
        uriParts.shift();
    }

    return uriParts.join('.');
}

/* exported openStatistics */
var openStatistics = function ()
{
    'use strict';

    window.open('http://minisitex.enetscores.com/stats/soccer');
};

/* exported openLiveResults */
var openLiveResults = function ()
{
    'use strict';

    window.open('http://minisitex.enetscores.com/livescore/soccer');
};

/* exported OpenStats */
/* jshint maxcomplexity: 6 */
var OpenStats = function (homeEnetplusID, awayEnetplusID, BranchEnetPulseID)
{
    'use strict';

    if (BranchEnetPulseID === '')
    {
        BranchEnetPulseID = 's';
    }

    if (homeEnetplusID === null || awayEnetplusID === null || BranchEnetPulseID === null)
    {
        return;
    }
    var url = 'http://minisitex.enetscores.com/redirect/h2h/resolve_h2h_enetids?lang=en&stats=true&h_id=' + homeEnetplusID + '&a_id=' + awayEnetplusID + '&g=' + BranchEnetPulseID;
    var feat = 'left=100,top=50,width=1070,height=600,toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=no,copyhistory=no';
    var wind = window.open(url, 'Statistics', feat);
    if (wind === null)
    {
        displayError('Please disable popup blockers to allow statistics window to open.');
    }
};
/* jshint maxcomplexity: 5 */

/* exported handleInputFields */
/* Input field type=text suggestion handler + exception for the password field in the login form */
var handleInputFields = function (obj)
{
    'use strict';

    //Check if data is set
    if (obj.data('defaultContent') === null)
    {
        //If there is no data set, set the current value as data and clear the field
        obj.data('defaultContent', obj.val());
        obj.val('');
    }
    else
    {
        //Compare data with input's currnet value
        if (obj.val() != obj.data('defaultContent') && obj.val() !== '' && obj.val() !== ' ')
        {
            return false;
        }
        else
        {
            obj.val('');
        }
    }
    //Set a check when field has lost focus
    obj.blur(function ()
    {
        if ($(this).val() != obj.data('defaultContent') && $(this).val() !== '' && $(this).val() !== ' ')
        {
            return false;
        }
        else
        {
            $(this).val(obj.data('defaultContent'));
        }
        //Unbind the blur event
        $(this).unbind();
    });
};

/* exported onDocumentReady */
var onDocumentReady = function ()
{
};

/* exported IsShowCurrencyCode */
var IsShowCurrencyCode = true;

//Handle the password field in the login area whith a bit more care :)
window.addEventListener('load ', function () {
    'use strict';

    onDocumentReady();
});

/* exported SessionExpirationInterval */
var SessionExpirationInterval = 1200000;

typeof extDict !== "undefined" && extDict['/JSComponents/WHLSpecificExt.js']();

window.APIUser = APIUser;
window.IsAPIDefined = IsAPIDefined;
window.UseCaptchaLogin = UseCaptchaLogin;
window.APIDomain = APIDomain;
window.openStatistics = openStatistics;
window.openLiveResults = openLiveResults;
window.OpenStats = OpenStats;
window.handleInputFields = handleInputFields;
window.onDocumentReady = onDocumentReady;
window.IsShowCurrencyCode = IsShowCurrencyCode;
window.SessionExpirationInterval = SessionExpirationInterval;




