﻿window.useCaptchaLogin = true;

var whl =
{
    doLogin: () => {},
    mobileLoginTS: () => {},
    moblieCloseForm: function()
    {
        page.router.goBack();
    },
    logout: () => {},
    adminLogin: () => {},
    formClose: () => {},
    changePasswordForm: () => {},
    forgotPasswordForm: () => {},
    resetPassword: () => {},
    showDialog: () => {},
    changePassword: (oldPass, newPass, callback) =>  callback({ status: 'success' }),
    refreshSession: (callback) => callback({ status: 'success' }),
    status: (callback) => callback({ status: 'success' }),
    updateBalance: (callback) => callback(),
    getMobileRegistrationURL: function()
    {
        return this.siteURL + '/MobileRegister.html';
    },
    getMobileBankURL: function()
    {
        return this.siteURL + '/MobileBank.html';
    },
    getMobilePasswordResetURL: function()
    {
        return this.siteURL + '/MobileResetPassword.html';
    }
};

if (!window.whl) {
    window.whl = whl;
}

typeof extDict !== "undefined" && extDict['/JSComponents/DirectAPI.ext.js']();
