﻿(function() {
    function SetLastActivityTime()
    {
        StorageUtils.saveToStorage('LAST_ACTIVITY_TIME', (new Date()).getTime(), localStorage);
    }

    function getLastActivityTime()
    {
        return StorageUtils.getFromStorage('LAST_ACTIVITY_TIME', localStorage);
    }

    SetLastActivityTime();

    document.addEventListener('touchstart', SetLastActivityTime, false);

    document.addEventListener('mousemove', SetLastActivityTime, false);

    window.SetLastActivityTime = SetLastActivityTime;
    window.getLastActivityTime = getLastActivityTime;

})();